import { storage } from "@/firebase/firebase";
import { useAppStore } from "@/store/AppStore";

const appStore = useAppStore();

export const AGREEMENT_FILE_NAME = "Teenuse osutamise leping.pdf";

export const getSubscriptionAgreement = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const loadingID = appStore.addLoading();
    const agreementRef = storage.ref(`/documents/${AGREEMENT_FILE_NAME}`);
    agreementRef
      .getDownloadURL()
      .then((url) => resolve(url))
      .catch((err) => reject(err))
      .finally(() => appStore.removeLoading(loadingID));
  });
};
