
import { computed, defineComponent, watchEffect } from "vue";
import { useUserStore } from "@/store/UserStore";

import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";
import { useRouter } from "vue-router";
import { RouteName } from "@/router/types";
import {
  AGREEMENT_FILE_NAME,
  getSubscriptionAgreement,
} from "@/firebase/storage/document";
import LiitumisAvaldusForm from "@/components/liitumisavaldus/LiitumisAvaldusForm.vue";
import LiitumisAvaldusDescription from "@/components/liitumisavaldus/LiitumisAvaldusDescription.vue";

export default defineComponent({
  name: "LiitumisAvaldus",
  components: {
    LiitumisAvaldusDescription,
    LiitumisAvaldusForm,
    MunicipalityPathGuard,
  },
  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();
    const router = useRouter();
    const isUserActive = computed(() => userState.userInfo?.active);
    watchEffect(() => {
      if (isUserActive.value && userState.userInfo?.municipalitySlug) {
        router.push({
          name: RouteName.MUNICIPALITY_HOME,
          params: { municipalitySlug: userState.userInfo.municipalitySlug },
        });
      }
    });

    const getAgreement = async () => {
      const downloadURL = await getSubscriptionAgreement();
      const element = document.createElement("a");
      element.setAttribute("href", downloadURL);
      element.setAttribute("download", AGREEMENT_FILE_NAME);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    return { getAgreement };
  },
});
