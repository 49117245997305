
import { defineComponent, ref } from "vue";
import { addAgreementApplicationFun } from "@/firebase/functions/functions";

export default defineComponent({
  name: "LiitumisAvaldusForm",
  setup() {
    const firstName = ref<string | null>(null);
    const lastName = ref<string | null>(null);
    const personalNo = ref<number | null>(null);
    const phone = ref<string | null>(null);
    const message = ref<string | null>(null);

    const onSubmit = async () => {
      await addAgreementApplicationFun({
        firstName: firstName.value,
        lastName: lastName.value,
        personalNo: personalNo.value,
        phone: phone.value,
        message: message.value,
      });
      firstName.value = null;
      lastName.value = null;
      personalNo.value = null;
      phone.value = null;
      message.value = null;
    };

    return { onSubmit, firstName, lastName, personalNo, phone, message };
  },
});
