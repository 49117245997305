<template>
  <!-- Contact form -->
  <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
    <form
      @submit.prevent="onSubmit"
      class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <div>
        <label for="first-name" class="block text-md font-normal text-black"
          >Esindaja eesnimi</label
        >
        <div class="mt-1">
          <input
            type="text"
            name="first-name"
            id="first-name"
            autocomplete="given-name"
            v-model="firstName"
            class="
              py-2.5
              px-4
              block
              w-full
              shadow-sm
              text-black
              focus:outline-none
              focus:ring-indigo-500
              focus:border-indigo-500
              border-gray-300
              rounded
              transition
              duration-300
              ease-in-out
            "
          />
        </div>
      </div>
      <div>
        <label for="last-name" class="block text-md font-normal text-black"
          >Esindaja perekonnanimi</label
        >
        <div class="mt-1">
          <input
            type="text"
            name="last-name"
            id="last-name"
            autocomplete="family-name"
            v-model="lastName"
            class="
              py-2.5
              px-4
              block
              w-full
              shadow-sm
              text-black
              focus:outline-none
              focus:ring-indigo-500
              focus:border-indigo-500
              border-gray-300
              rounded
              transition
              duration-300
              ease-in-out
            "
          />
        </div>
      </div>
      <div>
        <label for="persnonalNo" class="block text-md font-normal text-black"
          >Esindaja isikukood</label
        >
        <div class="mt-1">
          <input
            id="persnonalNo"
            name="persnonalNo"
            type="number"
            v-model.number="personalNo"
            class="
              py-2.5
              px-4
              block
              w-full
              shadow-sm
              text-black
              focus:outline-none
              focus:ring-indigo-500
              focus:border-indigo-500
              border-gray-300
              rounded
              transition
              duration-300
              ease-in-out
            "
          />
        </div>
      </div>
      <div>
        <div class="flex justify-between">
          <label for="phone" class="block text-md font-normal text-black"
            >Telefon</label
          >
        </div>
        <div class="mt-1">
          <input
            type="text"
            name="phone"
            id="phone"
            autocomplete="tel"
            v-model="phone"
            class="
              py-2.5
              px-4
              block
              w-full
              shadow-sm
              text-black
              focus:outline-none
              focus:ring-indigo-500
              focus:border-indigo-500
              border-gray-300
              rounded
              transition
              duration-300
              ease-in-out
            "
            aria-describedby="phone-optional"
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <div class="flex justify-between">
          <label for="message" class="block text-md font-normal text-black"
            >Lisainfo ja küsimused</label
          >
          <span id="message-max" class="text-md text-gray-500"
            >(Ei ole kohustuslik)</span
          >
        </div>
        <div class="mt-1">
          <textarea
            id="message"
            name="message"
            rows="4"
            v-model="message"
            class="
              py-2.5
              px-4
              block
              w-full
              shadow-sm
              text-black
              focus:outline-none
              focus:ring-indigo-500
              focus:border-indigo-500
              border border-gray-300
              rounded
              transition
              duration-300
              ease-in-out
            "
            aria-describedby="message-max"
          ></textarea>
        </div>
      </div>
      <div class="sm:col-span-2 sm:flex sm:justify-end">
        <button
          type="submit"
          class="
            mt-2
            w-full
            inline-flex
            items-center
            justify-center
            px-6
            py-2.5
            border border-indigo-600
            rounded
            shadow-sm
            text-base
            font-normal
            text-white
            bg-indigo-600
            hover:text-indigo-600
            hover:bg-transparent
            focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
            sm:w-auto
            transition
            duration-300
            ease-in-out
          "
        >
          Saada
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { addAgreementApplicationFun } from "@/firebase/functions/functions";

export default defineComponent({
  name: "LiitumisAvaldusForm",
  setup() {
    const firstName = ref<string | null>(null);
    const lastName = ref<string | null>(null);
    const personalNo = ref<number | null>(null);
    const phone = ref<string | null>(null);
    const message = ref<string | null>(null);

    const onSubmit = async () => {
      await addAgreementApplicationFun({
        firstName: firstName.value,
        lastName: lastName.value,
        personalNo: personalNo.value,
        phone: phone.value,
        message: message.value,
      });
      firstName.value = null;
      lastName.value = null;
      personalNo.value = null;
      phone.value = null;
      message.value = null;
    };

    return { onSubmit, firstName, lastName, personalNo, phone, message };
  },
});
</script>

<style scoped></style>
