<template>
  <MunicipalityPathGuard>
    <div class="max-w-7xl mx-auto pb-16 pt-12 sm:pb-24 sm:px-6 lg:px-8">
      <div class="inline-flex text-center justify-center w-full">
        <p
          @click="getAgreement"
          class="cursor-pointer text-base text-center pb-3 text-gray-500"
        >
          Lepingutingimustega tutvumiseks klikkige
          <span
            class="
              font-medium
              text-gray-700
              underline
              hover:text-primary-teal
              transition
              duration-300
              ease-in-out
              tracking-wide
            "
            >siia!</span
          >
        </p>
      </div>
      <div class="relative bg-white shadow-big rounded-md">
        <div class="grid grid-cols-1 lg:grid-cols-3">
          <LiitumisAvaldusDescription />
          <LiitumisAvaldusForm />
        </div>
      </div>
    </div>
  </MunicipalityPathGuard>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from "vue";
import { useUserStore } from "@/store/UserStore";

import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";
import { useRouter } from "vue-router";
import { RouteName } from "@/router/types";
import {
  AGREEMENT_FILE_NAME,
  getSubscriptionAgreement,
} from "@/firebase/storage/document";
import LiitumisAvaldusForm from "@/components/liitumisavaldus/LiitumisAvaldusForm.vue";
import LiitumisAvaldusDescription from "@/components/liitumisavaldus/LiitumisAvaldusDescription.vue";

export default defineComponent({
  name: "LiitumisAvaldus",
  components: {
    LiitumisAvaldusDescription,
    LiitumisAvaldusForm,
    MunicipalityPathGuard,
  },
  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();
    const router = useRouter();
    const isUserActive = computed(() => userState.userInfo?.active);
    watchEffect(() => {
      if (isUserActive.value && userState.userInfo?.municipalitySlug) {
        router.push({
          name: RouteName.MUNICIPALITY_HOME,
          params: { municipalitySlug: userState.userInfo.municipalitySlug },
        });
      }
    });

    const getAgreement = async () => {
      const downloadURL = await getSubscriptionAgreement();
      const element = document.createElement("a");
      element.setAttribute("href", downloadURL);
      element.setAttribute("download", AGREEMENT_FILE_NAME);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    return { getAgreement };
  },
});
</script>

<style scoped></style>
