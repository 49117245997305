<template>
  <div
    class="relative overflow-hidden sm:rounded-l-md sm:rounded-r-md lg:rounded-r-none py-10 px-6 bg-indigo-700 sm:px-10 xl:p-12"
  >
    <div
      class="absolute inset-0 pointer-events-none sm:hidden"
      aria-hidden="true"
    >
      <svg
        class="absolute inset-0 w-full h-full"
        width="343"
        height="388"
        viewBox="0 0 343 388"
        fill="none"
        preserveAspectRatio="xMidYMid slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
          fill="url(#linear1)"
          fill-opacity=".1"
        />
        <defs>
          <linearGradient
            id="linear1"
            x1="254.553"
            y1="107.554"
            x2="961.66"
            y2="814.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff"></stop>
            <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div
      class="
        hidden
        absolute
        top-0
        right-0
        bottom-0
        w-1/2
        pointer-events-none
        sm:block
        lg:hidden
      "
      aria-hidden="true"
    >
      <svg
        class="absolute inset-0 w-full h-full"
        width="359"
        height="339"
        viewBox="0 0 359 339"
        fill="none"
        preserveAspectRatio="xMidYMid slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
          fill="url(#linear2)"
          fill-opacity=".1"
        />
        <defs>
          <linearGradient
            id="linear2"
            x1="192.553"
            y1="28.553"
            x2="899.66"
            y2="735.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff"></stop>
            <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div
      class="
        hidden
        absolute
        top-0
        right-0
        bottom-0
        w-1/2
        pointer-events-none
        lg:block
      "
      aria-hidden="true"
    >
      <svg
        class="absolute inset-0 w-full h-full"
        width="160"
        height="678"
        viewBox="0 0 160 678"
        fill="none"
        preserveAspectRatio="xMidYMid slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
          fill="url(#linear3)"
          fill-opacity=".1"
        />
        <defs>
          <linearGradient
            id="linear3"
            x1="192.553"
            y1="325.553"
            x2="899.66"
            y2="1032.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff"></stop>
            <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <h3 class="text-3xl font-normal text-white">Liitumisavaldus</h3>
    <p class="mt-6 text-base font-normal text-indigo-50 max-w-3xl">
      Liitumiseks palun täidke väljad ja saatke meile avaldus.
    </p>
    <div class="mt-8">
      <span class="sr-only">Email</span>
      <div class="inline-flex items-center text-base text-indigo-50">
        <!-- Heroicon name: outline/mail -->
        <svg
          class="flex-shrink-0 w-8 h-8 text-indigo-200"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
        <span class="ml-3">info@andmik.ee</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LiitumisAvaldusDescription",
  setup() {
    return {};
  },
});
</script>

<style scoped>
</style>